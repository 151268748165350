import {createContext} from 'react';
import {Offer} from "lib/api/backend.schemas";

interface KlmContextProps {
  availableOffers: [];
  offers: {};
  initialized: boolean;
  selectedOffer?: Offer;
}

export const KlmContext = createContext<KlmContextProps>({} as KlmContextProps);
