import {Control, Controller} from 'react-hook-form';

import {Box, InputAdornment} from '@mui/material';

import {TextField} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

const InputVehicle = ({control, isWidget}: {control: Control<any, any>; isWidget: boolean}) => {
  return (
    <Box sx={{width: '100%'}}>
      <Controller
        control={control}
        name="vehicle"
        render={({field: {name, onBlur, value, ref}}) => {
          return (
            <TextField
              ref={ref}
              variant={isWidget ? 'standard' : 'filled'}
              fullWidth
              name={name}
              disabled
              label={'Ihr gewähltes Wunschfahrzeug'}
              inputProps={{inputMode: 'numeric'}}
              onBlur={onBlur}
              value={value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CustomIcon
                      name="car"
                      style={{
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </Box>
  );
};
export default withErrorBoundary(InputVehicle, 'InputVehicle');
